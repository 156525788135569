import { startTransition, useEffect, useRef, useState } from 'react';

import { useScroll } from 'react-use';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/eripiowear/hooks';
import { GlobalFields, MenuItemFragment } from '@hultafors/eripiowear/types';

import LogoSvg from '../../svg/eripiowear_horizontal.svg';
import {
  DESKTOP_MENU_DRAWER_ID,
  DesktopMenuType,
} from '../desktop-menu/desktop-menu';
import { HeaderStoreItem } from '../header-store-item/header-store-item';
import { SlideInToggler } from '../slide-in/slide-in';

import LanguageSvg from './language.svg';
import MenuTogglerSvg from './menuToggler.svg';
import {
  ChangeCountry,
  DesktopNav,
  Logo,
  MenuActions,
  MenuToggler,
  MobileNav,
  NavigationStyled,
  Partner,
  Search,
  TopActions,
  TopActionsLabel,
  TopMenu,
  TopMenuItem,
  TopMenuProducts,
} from './navigation.styled';
import PartnerSvg from './partner.svg';
import SearchSvg from './search.svg';

export interface NavigationProps {
  disabled?: boolean;
  isTransparent?: boolean;
  isOpen?: boolean;
  toggleMenu: SlideInToggler;
  // toggleDesktopMenu(type: DesktopMenuType | null): void;
  toggleDesktopMenu: SlideInToggler<DesktopMenuType>;
  toggleFavoritesMenu(force?: boolean): void;
  openDesktopMenuType?: DesktopMenuType | null;
  menuData: GlobalFields['allMenuItems'];
}

export const Navigation: React.FC<NavigationProps> = ({
  toggleDesktopMenu,
  toggleMenu,
  toggleFavoritesMenu,
  isTransparent,
  isOpen,
  openDesktopMenuType,
}) => {
  const [scrolledDown, setScrolledDown] = useState<boolean>(false);
  const comp = useRef(null);
  const { globalContent, allMenuItems, site } = useGlobal();
  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);
  const transparent = isTransparent && !scrolledDown && !isOpen;
  const scrollDownThreshold = 100;
  const { activeMarket, toggle } = useMarketPicker();

  useEffect(() => {
    if (comp.current && isTransparent) {
      startTransition(() => {
        setScrolledDown(y > scrollDownThreshold);
      });
    }
  }, [y]);

  const menuTogglerOnClick = () => toggleMenu();
  const favoritesTogglerOnClick = () => toggleFavoritesMenu();

  const onClickSearch = () => {
    toggleDesktopMenu('search');
  };

  function topMenuMapper(item: MenuItemFragment) {
    if (!item.page?.slug) {
      return null;
    }
    const key = `MenuItem-${item.id}`;
    const menuType = 'category';
    const active = openDesktopMenuType === menuType;
    const onClick: React.MouseEventHandler = (event) => {
      event.preventDefault();
      toggleDesktopMenu(menuType);
    };
    if (item.sections?.length) {
      return (
        <TopMenuProducts
          key={key}
          onClick={onClick}
          $active={active ? 'true' : undefined}
          href={item.page.slug}
          data-slidein-ignore={DESKTOP_MENU_DRAWER_ID}
        >
          {item.label}
        </TopMenuProducts>
      );
    }
    if (item) {
      return (
        <TopMenuItem key={key} href={item.page.slug}>
          {item.label}
        </TopMenuItem>
      );
    }
    return null;
  }

  function renderLanguageIcon() {
    let iconUrl;

    if (!activeMarket || (!activeMarket?.flag && !activeMarket?.symbol?.url)) {
      return <LanguageSvg focusable={false} aria-hidden={true} height={24} />;
    }
    if (activeMarket?.flag && !activeMarket?.symbol?.url) {
      iconUrl = `https://flagcdn.com/${activeMarket.flag}.svg`;
    }
    if (activeMarket?.symbol?.url) {
      iconUrl = activeMarket.symbol.url;
    }
    return (
      <img
        src={iconUrl ?? ''}
        width={24}
        height={24}
        alt={activeMarket.name ?? ''}
      />
    );
  }

  return (
    <NavigationStyled
      $transparent={isTransparent && !scrolledDown && !isOpen}
      ref={comp}
    >
      <MobileNav>
        <MenuToggler
          onClick={menuTogglerOnClick}
          aria-label={globalContent?.menu || ''}
        >
          <MenuTogglerSvg
            width={20}
            height={20}
            aria-hidden={true}
            focusable={false}
          />
        </MenuToggler>

        <Logo href="/" aria-label={site.globalSeo?.siteName || 'Eripiowear'}>
          <LogoSvg
            width={235}
            height={25}
            focusable={false}
            aria-hidden={true}
          />
        </Logo>

        <MenuActions>
          <HeaderStoreItem isFavorite onClick={favoritesTogglerOnClick} />
        </MenuActions>
      </MobileNav>

      <DesktopNav>
        <Logo href="/" aria-label={site.globalSeo?.siteName || 'Eripiowear'}>
          <LogoSvg
            width={235}
            height={25}
            focusable={false}
            aria-hidden={true}
          />
        </Logo>
        <TopMenu>{allMenuItems?.map(topMenuMapper)}</TopMenu>
        <TopActions $transparent={transparent}>
          <ChangeCountry onClick={() => toggle(true)}>
            {renderLanguageIcon()}
            <TopActionsLabel $transparent={transparent}>
              {globalContent?.changeLanguage ?? ''}
            </TopActionsLabel>
          </ChangeCountry>

          <Search
            onClick={onClickSearch}
            data-slidein-ignore={DESKTOP_MENU_DRAWER_ID}
            aria-label={globalContent.search || 'Search'}
          >
            <SearchSvg focusable={false} aria-hidden={true} height={24} />
            <TopActionsLabel $transparent={transparent}>
              {globalContent.search || ''}
            </TopActionsLabel>
          </Search>
          <HeaderStoreItem
            isFavorite
            onClick={favoritesTogglerOnClick}
            iconText={globalContent.favorites || ''}
          />
          {globalContent.partnerPortalUrl && (
            <Partner
              href={globalContent.partnerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PartnerSvg focusable={false} aria-hidden={true} height={24} />
              <TopActionsLabel>Partner</TopActionsLabel>
            </Partner>
          )}
        </TopActions>
      </DesktopNav>
    </NavigationStyled>
  );
};
